import { NavLink } from "react-router-dom"
import Logo from "./Logo"

function Navbar() {
    return (
        <>
            <div id="navigation-content">
            <div className="logo">
                <Logo />
            </div>
            <div className="navigation-close">
                <span className="close-first"></span>
                <span className="close-second"></span>
            </div>
            <div className="navigation-links">
                <NavLink to="/" data-wrap-text="INI CIO" data-text="INICIO" id="home-link" >INICIO</NavLink>
                <NavLink to="/About" data-wrap-text="SO BRE MI" data-text="SOBRE MI" id="about-link" >SOBRE MI</NavLink>
                <NavLink to="/Blog" data-wrap-text="BL OG" data-text="BLOG" id="blog-link" >BLOG</NavLink>
                <NavLink to="/Work" data-wrap-text="TRA BA JO" data-text="TRABAJO" id="portfolio-link" >TRABAJO</NavLink>
                <NavLink to="/Contact" data-wrap-text="CON TA CTO" data-text="CONTACTO" id="contact-link" >CONTACTO</NavLink>
            </div>
            </div>
            <div id="navigation-bar">
                <Logo />
                <div className="menubar">
                    <span className="first-span"></span>
                    <span className="second-span"></span>
                    <span className="third-span"></span>
                </div>
            </div>
        </>
    )
}

export default Navbar