import React from 'react'
import ColorChanger from "../components/ColorChanger";
import Footer from '../components/Footer'
import CertificationAnimation from '../components/CertificationAnimation';
import SkillsAccordion from '../components/SkillsAccordion';

function About() {
  return (
    <div id="about">
      <ColorChanger />
      <div id="about-content">
        <div className="about-header">
          Sobre <span className="color">Mi</span>
          <span className="header-caption">Conoce<span className="color">me.</span></span>
        </div>
        <div className="about-main">
          <div className="about-first-paragraph wow">
            <span className="about-first-line">
              Soy un desarrollador&ensp;
              <span className="color">Ssr. </span>Certificado en<span className="color"> Power Platform</span>
              &ensp;</span>
            <br />
            <span className="about-second-line"> Llevo trabajando en Dynamics casi 3 años y me pude certificar como Power Platform Dev. Associate y Power Platform Solutions Architect. <br/>Hasta el dia de hoy sigo perfeccionando mis habilidades tecnicas como blandas.</span>
            <div className="cv">
              <a target='_blank' href="https://www.luretecs.com/CV%20Lucas%20Contreras.pdf"><button style={{fontFamily:'poppins'}}>Download <span className="colors">CV</span></button></a>
            </div>
          </div>
          <div className="about-img">
            
            <CertificationAnimation />
          </div>
        </div>
      </div>
      <div id="services">
        <ColorChanger />
        <div className="services-heading wow">
        ¿Que<span className="color"> ofrezco?</span>
        </div>
        <div className="services-content">
          <div className="service-one service wow">
            <div className="service-img">
              <img src="https://www.luretecs.com/images/coding.png" alt="service-one" />
            </div>
            <div className="service-description">
              <h2>Desarrollo nativo, funcional y seguro</h2>
              <p>Siempre busco soluciones nativas que se adapten al negocio con el minimo riesgo sino las desarrollo utilizando las mejores practicas</p>
            </div>
          </div>
          <div className="service-two service wow">
            <div className="service-img">
              <img color='white' src="https://img.icons8.com/sf-regular/48/FFFFFF/link.png" alt="service-two" />
            </div>
            <div className="service-description">
              <h2>Experiencia en integraciones</h2>
              <p>Se crear integraciones a/desde Dynamics con APIs, Warehoses o servidores para migrar o manipular datos. </p>
            </div>
          </div>
          <div className="service-three service wow">
            <div className="service-img">
              <img src="https://www.luretecs.com/images/bulb.png" alt="service-three" />
            </div>
            <div className="service-description">
              <h2>Diseño innovador, continuo y evolutivo</h2>
              <p>Desarrollo Apps, Webs y componentes usando siempre los nuevos estandares y frameworks para ofrecer mas al negocio.</p>
            </div>
          </div>
        </div>
      </div>
      <div id="skills">
        <div className="skills-header">
          Mis <span className="color"> Skills</span>
        </div>
        <div className="skills-content " style={{display: "flex",flexDirection: "column",alignItems: "center"}}>
          <SkillsAccordion />
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default About