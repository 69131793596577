import React from 'react'
import { Link } from 'react-router-dom'
import {ReactTyped} from 'react-typed';
//import pJS from 'particles.js';

function Home() {
  const enPhrases = [ " D365 Developer.", " Web Designer.", " Solutions Architect." ];
  const phrases = [ " Ssr. D365 Dev.", " Diseñador Web.", " Desarrollador RPA." ];
  return (
    <div id="header">
      <div id="particles" ></div>
      <div className='header-menu'>
        <div className="social-media-links">
          <Link style={{margin:"10px"}} target="_blank" to="https://www.instagram.com/lure_untitled/"><img src="https://www.luretecs.com/images/instagram logo.png" className="social-media" alt="instagram-logo" /></Link>
          <Link style={{margin:"10px"}} target="_blank" to="https://www.linkedin.com/in/lucas-marcelo-contreras/"><img src="https://www.luretecs.com/images/linkedin logo.png" className="social-media" alt="linkedin-logo" /></Link>
        </div>
        <div className="contact">
          <Link to="Mailto:lucastec2002@hotmail.com"><img src="https://www.luretecs.com/images/mail.png" alt="email-pic" className="contactpic" /></Link>
          <Link to="Tel:1150987593"><img src="https://www.luretecs.com/images/call.png" alt="phone-pic" className="contactpic" /></Link>
        </div>
      </div>
      <div className="header-content" >
        <div className="header-content-box">
          <div className="firstline"><span className="color">Lucas </span>Contreras</div>
          <div className="secondline">
            Soy un&ensp;
            <ReactTyped
              className="color"
              strings={phrases}
              typeSpeed={100} // Velocidad de escritura
              backSpeed={70} // Velocidad de borrado
              loop // Loops infinito
            />
          </div>
        </div>
      </div>
      <div className="header-image">
        <img src="https://www.luretecs.com/images/man3.png" alt="logo" />
      </div>
    </div>
  )
}

export default Home