import React from 'react'
import ColorChanger from "../components/ColorChanger";
import Footer from '../components/Footer'
import PortFolioCard from '../components/PortFolioCard'

function Work() {
  return (
    <div id="portfolio">
      <ColorChanger />
      <div className="portfolio-header"> <span className="color"> Mi </span> Portafolio
        <span className="header-caption"> Algunos de mis <span className="color"> Trabajos</span></span></div>
      <div id="portfolio-content">
        <PortFolioCard className="" title="App Idea" text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad ut optio repellat cupiditate
              expedita eius dignissimos. Id cumque placeat minima ad laudantium suscipit voluptatem ducimus" imgSrc={"https://www.luretecs.com/images/portfolio-first.jpg"} />
        <PortFolioCard className="" title="Web Designing" text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad ut optio repellat cupiditate
              expedita eius dignissimos. Id cumque placeat minima ad laudantium suscipit voluptatem ducimus" imgSrc={"https://www.luretecs.com/images/portfolio-second.jpg"} />
        <PortFolioCard className="" title="UI Designing" text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad ut optio repellat cupiditate
              expedita eius dignissimos. Id cumque placeat minima ad laudantium suscipit voluptatem ducimus" imgSrc={"https://www.luretecs.com/images/portfolio-third.jpg"} />
        <PortFolioCard className="" title="Wow Graphics" text="Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ad ut optio repellat cupiditate
              expedita eius dignissimos. Id cumque placeat minima ad laudantium suscipit voluptatem ducimus" imgSrc={"https://www.luretecs.com/images/portfolio-fourth.jpg"} />
      </div>
      <Footer />
    </div>
  )
}

export default Work