import React from 'react'
import $ from 'jquery';

function ColorChanger() {
    const colors = [
        "#c70039",
        "#481380",
        "#00909e",
        "#04A777",
        "#035aa6",
        "#dd7631"
    ];
    const colorPanelHandleClick = function (e) {
        e.preventDefault();
        $('.color-changer').toggleClass('color-changer-active');
    };
    const colorHandleClick = function (e) {
        e.preventDefault();
        var attr = e.currentTarget.title;
        $('head').append('<style>*{--primaryColor:' + attr + ';}</style>');
    };
    return (
        <div className="color-changer" onClick={colorPanelHandleClick}>
            <div className="color-panel">
                <img src="https://www.luretecs.com/images/gear.png" alt="" />
            </div>
            <div className="color-selector">
                <div className="heading">Colores</div>
                <div className="colors">
                    <ul style={{padding:0,margin:0}} >
                        {colors.map( (color, index) => (
                            <li key={index}>
                                <a style={{backgroundColor:color}} href="#0" className={color} title={color} onClick={colorHandleClick} ></a>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ColorChanger