import React, { useMemo, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadFull } from "tsparticles";

const CertificationAnimation = () => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadFull(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options = {
    fullScreen: { enable: false },
    particles: {
      color: { value: ["#5B9BD5", "#7030A0"] },
      move: {
        enable: true,
        speed: 1,
        direction: "top",
        outModes: { default: "out" },
      },
      number: {
        value: 100,
        density: { enable: true, area: 10 },
      },
      size: {
        value: 2,
        random: { enable: true, minimumValue: 2 },
      },
      opacity: {
        value: 0.8,
        random: { enable: true, minimumValue: 0.5 },
      },
    },
  };

  if (init) {
    return (
      <div >
        <img src="https://www.luretecs.com/images/about.jpg" alt="Lucas Contreras" style={{ objectFit: 'cover' }} />
        {/* Certificación 1 */}
        <motion.img
          src="/images/ppda.png"
          alt="Certificación 1"
          initial={{ y: 0 }}
          animate={{ y: [0, -10, 0] }}
          transition={{ repeat: Infinity, duration: 2, ease: "easeInOut" }}
          style={{
            position: "absolute",
            bottom: "-10px",
            right: "7rem",
            width: "12rem",
            height: "12rem",
            zIndex: 2,
            filter: 'drop-shadow(0 0 0.75rem rgba(91, 154, 213, 0.61))'
          }}
        />

        {/* Certificación 2 */}
        <motion.img
          src="/images/ppsa.png"
          alt="Certificación 2"
          initial={{ y: 0 }}
          animate={{ y: [0, -15, 0] }}
          transition={{ repeat: Infinity, duration: 2.5, ease: "easeInOut" }}
          style={{
            position: "absolute",
            bottom: "20px",
            right: "15rem",
            width: "12rem",
            height: "12rem",
            zIndex: 1,
            filter: "drop-shadow(0 0 1rem rgb(58, 27, 115))",
          }}
        />
        {/* <Particles
          id="tsparticles"
          options={options}
          style={{
            position: "absolute !important",
            bottom: "0",
            right: "0",
            width: "22rem",
            height: "11rem",
            zIndex: 1000
          }}
        /> */}
      </div>
    );
  }
  return <></>
};

export default CertificationAnimation;
