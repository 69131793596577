import React from 'react'
import ColorChanger from "../components/ColorChanger";
import Footer from '../components/Footer'
import PostBlog from '../components/PostBlog'

function Blog() {
    const blogData = [
        {
          imgSrc: "https://www.luretecs.com/images/post-one.jpg",
          postDate: "8 May,20",
          postLink: "#",
          title: "Harleys In Hawaai",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus alias dolore recusandae illum, corrupti quo veniam saepe aliquid! Quis voluptates ratione consequuntur vel, perferendis cum provident? Magnam fugiat voluptas libero."
        },
        {
          imgSrc: "https://www.luretecs.com/images/post-two.jpg",
          postDate: "16 Jan,20",
          postLink: "#",
          title: "Key To Be Productive",
          text: "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Incidunt maiores, recusandae cupiditate ducimus a non tempora, architecto obcaecati eaque ipsum assumenda harum dolorum iusto tenetur eius eligendi dolor magnam sit!"
        },
        {
          imgSrc: "https://www.luretecs.com/images/post-three.jpg",
          postDate: "30 Nov,19",
          postLink: "#",
          title: "Caffeine Addict",
          text: "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nemo nostrum impedit ipsam perspiciatis ratione sapiente quasi optio reprehenderit, labore consequuntur suscipit cum quas. Officiis dolorem asperiores, ut necessitatibus quas doloremque?"
        },
        {
          imgSrc: "https://www.luretecs.com/images/post-four.jpg",
          postDate: "6 Jul,19",
          postLink: "#",
          title: "Web Development",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem, veniam ratione quam vitae, quibusdam explicabo rem debitis velit ipsa repellat, impedit nulla fuga? Amet corporis praesentium quae. Sed, quibusdam necessitatibus."
        },
        {
          imgSrc: "https://www.luretecs.com/images/post-five.jpg",
          postDate: "1 Jun,19",
          postLink: "#",
          title: "Work From Home",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Fuga sunt eum necessitatibus rem dignissimos nulla mollitia cumque, provident officiis non vitae? Animi aut doloremque illum, soluta hic minus sint explicabo.."
        },
        {
          imgSrc: "https://www.luretecs.com/images/post-six.jpg",
          postDate: "28 Feb,19",
          postLink: "#",
          title: "Business Trip",
          text: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Explicabo tempora dolorum fuga ratione, unde, ex quaerat iste numquam nemo nihil nobis rem sint quia recusandae dignissimos quos ut rerum nam."
        }
      ];
    return (
        <div id="blog">
            <ColorChanger />
            <div className="blog-header"> Blogs
                <span className="header-caption"> Mis ultimos
                    <span className="color"> blog posts.</span>
                </span>
            </div>
            <div className="blog-content">
                {blogData.map((blog, index) => (
                    <PostBlog
                    key={index}
                    imgSrc={blog.imgSrc}
                    postDate={blog.postDate}
                    postLink={blog.postLink}
                    title={blog.title}
                    text={blog.text}
                    />
                ))}
            </div>
            <Footer />
        </div>
    )
}

export default Blog