import React from 'react'
import ColorChanger from "../components/ColorChanger";
import Footer from '../components/Footer'

function Contact() {
  return (
    <div id="contact">
      <ColorChanger />
      <div class="contact-header">Contacta<span class="color">me</span>
        <div class="contact-header-caption"> <span class="color"> En el</span> momento.</div></div>
      <div class="contact-content">
        <div class="contact-form">
          <div class="form-header">
            Enviame un mensaje
          </div>
          <form id="myForm" action="#">
            <div class="input-line">
              <input id="name" type="text" placeholder="Name" class="input-name" />
              <input id="email" type="email" placeholder="Email" class="input-name" />
            </div>
            <input type="text" id="subject" placeholder="subject" class="input-subject" />
            <textarea style={{resize: 'none'}} id="body" class="input-textarea" placeholder="message"></textarea>
            <button type="button" id="submit" value="send">Enviar</button>
          </form>
        </div>
        <div class="contact-info" style={{lineHeight: 'normal'}}>
          <div class="contact-info-header">
            Info de Contacto
          </div>
          <div class="contact-info-content">
            <div class="contect-info-content-line">
              <img src="https://www.luretecs.com/images/icon-name.png" class="icon" alt="name-icon" />
              <div class="contact-info-icon-text">
                <h6>Nombre</h6>
                <p>Lucas Contreras</p>
              </div>
            </div>
            <div class="contect-info-content-line">
              <img src="https://www.luretecs.com/images/icon-location.png" class="icon" alt="location-icon" />
              <div class="contact-info-icon-text">
                <h6>Ubicacion</h6>
                <p>Buenos Aires, Argentina</p>
              </div>
            </div>
            <div class="contect-info-content-line">
              <img src="https://www.luretecs.com/images/icon-phone.png" class="icon" alt="phone-icon" />
              <div class="contact-info-icon-text">
                <h6>Numero</h6>
                <p>+11 5098-7593</p>
              </div>
            </div>
            <div class="contect-info-content-line">
              <img src="https://www.luretecs.com/images/icon-email.png" class="icon" alt="email-icon" />
              <div class="contact-info-icon-text">
                <h6>Correo</h6>
                <p>lucastec2002@hotmail.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Contact