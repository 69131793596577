import Accordion from 'react-bootstrap/Accordion';

function SkillsAccordion() {
  const skills = [
    {
      title: 'Power Platform',
      description: <p className='accordion-p'>Desarrolle en Power Platform desde mi primera expieriencia desarrollando Aplicaciones, Flujos, Reportes y mas.<br/>Trabaje con Power Apps, Power Automate, Power BI, Power Pages y Dataverse.</p>
    },
    {
      title: 'Sales, Customer Service & Customer Insights',
      description: <p className='accordion-p'>Me ha tocado personalizar y desarrollar soluciones para las aplicaciones de Dynamics 365 Sales, Customer Service y Customer Insights.<br/>Conozco las entidades, los componenetes y los procesos nativos de estos modulos, he hecho capacitaciones y POCs sobre estos.</p>
    },
    {
      title: 'Front-End: React.js & Typescript',
      description: <p className='accordion-p'>Diseñe aplicaciones web con React.js y Typescript, he trabajado con componentes, hooks, context, rutas y mas.<br/>Los he aplicado mayormente para paginas web, Power Component Frameworkse y Apps React embebidas en Dynamics.</p>
    },
    {
      title: 'Back-End: .NET C#, JS & API REST',
      description: <p className='accordion-p'>Desarrolle servicios y aplicaciones con .NET C# y JS, he trabajado con ADO.NET, LINQ, Web API, Azure Functions, Logic Apps y mas.<br/>He desarrollado servicios para integraciones, automatizaciones y aplicaciones.</p>
    },
    {
      title: 'Database: SQL Server',
      description: <p className='accordion-p'>He trabajado en Warehouses con SQL Server para almacenar, consultar y manipular datos.<br/>He desarrollado consultas, procedimientos almacenados, funciones y conexiones con DataFactory (Fabric) para la subida y bajada de datos.</p>
    },
    {
      title: 'Tools: WinForms & XrmToolBox apps',
      description: <p className='accordion-p'>Cree aplicaciones de escritorio con WinForms y aplicaciones para Dynamics 365 con XrmToolBox con el proposito de facilitar el trabajo del equipo y del negocio.</p>
    }
  ]
  return (
    <Accordion defaultActiveKey="0" style={{backgroundColor: 'transparent', width: '50%'}}>
      {
        skills.map((skill, key) => (
          <Accordion.Item key={key} eventKey={key.toString()}>
            <Accordion.Header>&ensp;&ensp;{skill.title}</Accordion.Header>
            <Accordion.Body>
              {skill.description}
            </Accordion.Body>
          </Accordion.Item>
        ))
      }
    </Accordion>
  );
}

export default SkillsAccordion;